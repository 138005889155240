import React from 'react'
import ProductTemplate from 'components/templates/ProductTemplate/ProductTemplate'

export default function ManagerTrainingPage() {
  const seoKeywords = ['SEO0', 'SEO1', 'SEO2']

  const products = {
    0: {
      productId: 'MT',
      productName: 'Formazione dirigente',
      productImages: {
        0: ['courses/corso-formazione-dirigente.jpg', 'corso formazione dirigente'],
      },
      productSize: 'Un corso formazione',
      productWeight: 0,
      productPrice: '10500',
      productPriceDesc: null,
      price: 'price_1J4oqyLde8wm4I0oqpfhlbrc',
    },
  }

  const description =
    '<strong>OBBLIGATORIETÀ:</strong> Obbligatorio per tutti i dirigenti di qualsiasi tipologia di azienda.<br/><br/> <strong>DURATA:</strong> 16 h<br/>'

  const presentation = {
    title: 'Formazione dirigente',
    description:
      '<strong>COME SI SVOLGE:</strong> ll corso si svolge online in modalità e-learning. All’acquisto vengono inviati link e credenziali con i quali collegarsi in qualsiasi momento, anche in più volte, dal proprio PC o dallo smart phone. Alla fine del corso è previsto un test finale che può essere sostenuto più volte, superato il quale viene rilasciato regolare attestato di avvenuta formazione, accreditato da Regione, in conformità al D. Lgs. 81/08 ed all’Accordo Stato Regioni del 21 dicembre 2011, che definisce durata e contenuti minimi del corso.<br/><br/> <strong>DESCRIZIONE:</strong> La formazione dei dirigenti, come previsto dall’Accordo Stato Regioni del 21.12.2011, sostituisce integralmente quella prevista per i lavoratori ed è strutturata in quattro moduli, per una durata complessiva di 16 ore.',
    photo: 'corso-formazione-dirigenti-diagramma.jpg',
  }

  return (
    <>
      <ProductTemplate
        seoKeywords={seoKeywords}
        products={products}
        description={description}
        presentation={presentation}
      />
    </>
  )
}
